import React, { Component } from 'react';
import { EventCard } from '../components/EventCard';
import Loader from '../components/Loader';
import vitoria from '../assets/images/vitoria.jpeg';
class Home extends Component {
    state = {
        imagesLoaded: false,
    };

    handleImageLoad = () => {
        this.setState({ imagesLoaded: true });
    };

    componentDidMount() {
        const images = [vitoria];
        let loadedImagesCount = 0;

        images.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImagesCount += 1;
                if (loadedImagesCount === images.length) {
                    this.handleImageLoad();
                }
            };
        });
    }

    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column pb-3 container">
                    <h1 className="page-title py-3 fs-4">Nossos Eventos</h1>
                    <hr className="hr" />
                    {!this.state.imagesLoaded ? (
                        <Loader />
                    ) : (
                        <div className="mb-3">
                            {/* <div className="d-flex flex-column aling-items-center">
                                <EventCard image={sp} backImg={sp2} url="https://forms.gle/KPrjVgEMVxL1n1DK8" />
                            </div>
                            <hr className="hr" /> */}
                            <div className="d-flex flex-column aling-items-center">
                                <EventCard image={vitoria} url="https://forms.gle/ZyocoDHoFieY6RxW9" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;
