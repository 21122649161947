import React, { Suspense, useState } from "react";
import Loader from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const EventCard = (props) => {
    const [currentImg, setCurrentImg] = useState(props.image);

    const handleImageHover = () => {
        if (props.backImg) {
            currentImg === props.image
                ? setCurrentImg(props.backImg)
                : setCurrentImg(props.image);
        }
    };

    return (
        <div className="d-flex flex-column aling-items-center">
            <Suspense fallback={<Loader />}>
                <LazyLoadImage
                    loading=" lazy"
                    src={currentImg}
                    alt="banner evento"
                    width="auto"
                    onClick={handleImageHover}
                    className={`${props.backImg ? "event-card" : ""}`}
                />
            </Suspense>
            {props.url && (
                <h5 className="pt-3 pb-1">
                    Garanta sua{" "}
                    <a
                        href={props.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        inscrição aqui
                    </a>
                    !
                </h5>
            )}
        </div>
    );
};
